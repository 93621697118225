.UnderConstruction {
  display: block; /* Remove flexbox layout temporarily */
  width: 100%;  /* Ensure the container can expand */
  max-width: 100%; /* No unnecessary constraints */
}

.UnderConstruction h1 {
  color: #333; /* Dark text color for headings */
}

.construction-img {
  width: auto;          /* Let the image scale naturally */
  max-width: 100%;      /* Ensure the image does not exceed the container width */
  height: auto;         /* Maintain aspect ratio */
}


/* Responsive image styling */
.img1 {
  position: absolute;
  top: 20px;
  left: 20px;
  max-width: 15%; /* Adjust as needed */
  margin-left: 5%; /* Adjust as needed */
  margin-top: 2%; /* Adjust as needed */
  align-self: center;
}

.img2 {
  position: absolute;
  top: 10%;    /* Moves the element 10px down from its normal position */
  right: 10%;   /* Moves the element 20px to the right from its normal position */
  height: auto;  /* Maintain aspect ratio */
  max-width: 60%; /* Adjust as needed */


}


/* Font-face declaration */
@font-face {
  font-family: 'MyFont';
  src: url('../../public/fonts/HeliosExt-Bold.otf') format('opentype'), /* Use opentype format */
       url('../../public/fonts/HeliosExt-Bold.otf') format('opentype'); /* Use opentype format */
  font-weight: normal;
  font-style: bold;
}

/* Apply the font to paragraphs */
.my-paragraph {
  position: absolute;
  top: 60%;    /* Moves the element 10px down from its normal position */
  left: 6%;   /* Moves the element 20px to the right from its normal position */
  font-family: 'MyFont', sans-serif; /* Fallback to sans-serif if font is not available */
  font-size: 5vw; /* Use viewport width for responsive font size */
  color: #FFFFFF;
  align-self: start;
  justify-self: unset;
}

@media (min-width: 1200px) {
  .construction-img {
    width: 600px;       /* Set larger size for desktop screens */
    height: auto;       /* Keep aspect ratio */
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .construction-img {
    width: 600px;       /* Adjust for tablet screens */
    height: auto;       /* Maintain aspect ratio */
  }
}


@media (max-width: 768px) {
  .construction-img {
    width: 600px;        /* Take full width for mobile screens */
    height: auto;       /* Maintain aspect ratio */
  }
  .my-paragraph {
    font-size: 35px; /* Adjust font size for better readability */
    top: 70%;    /* Moves the element 10px down from its normal position */
    left: 6%;   /* Moves the element 20px to the right from its normal position */
  }
  .img1 {
    max-width: 35%; /* Reduce image size for smaller screens */
    position: absolute;
    top: 30px;
    left: 20px;

  }
  .img2 {
    position: absolute;
    top: 20%;    /* Moves the element 10px down from its normal position */
    right: 2%;   /* Moves the element 20px to the right from its normal position */
    max-width: 80%; /* Adjust as needed */

  }

}