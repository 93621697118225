/* Global Styles */

body {
    background-image: url('https://objectstorage.me-abudhabi-1.oraclecloud.com/n/axipuvjwqndg/b/bucket-images/o/background.jpg');
    background-size: cover;  /* Adjust size as needed */
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;  /* Keeps the background fixed when scrolling */    
    margin: 0;
    padding: 0;
  }
  
  html, #root {
    height: 100%;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }
  