.loading-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #000000; /* Optionally set background color */
  }
  
  .loading-page img {
    width: 150px; /* Adjust the size of your image */
  }