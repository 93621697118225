/* src/MainApp.css */
.main-app {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .app-header {
    background-color: #282c34;
    color: white;
    padding: 20px;
    text-align: center;
  }
  
  .app-header nav ul {
    list-style-type: none;
    padding: 0;
  }
  
  .app-header nav ul li {
    display: inline;
    margin: 0 10px;
  }
  
  .app-header nav ul li a {
    color: white;
    text-decoration: none;
  }
  
  .app-header nav ul li a:hover {
    text-decoration: underline;
  }
  
  main {
    padding: 20px;
  }
  
  section {
    margin-bottom: 20px;
  }
  
  footer {
    background-color: #282c34;
    color: white;
    text-align: center;
    padding: 10px;
    position: fixed;
    width: 100%;
    bottom: 0;
  }
  